<template>
  <div class="container home">
    <div class="content">
      <div class="heading">
        <h1>Scrawl</h1>
      </div>
      <div class="cta">
        <a href="#login" class="log-in">Login</a>
        <a href="#signup" class="sign-up">Sign Up</a>
      </div>

      <div
        v-if="deferredPrompt"
        class="pwa-banner animate__animated animate__slideInUp animate__delay-3s"
      >
        <div class="close" @click="dismiss">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-x"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            @click="dismiss"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M18 6l-12 12"></path>
            <path d="M6 6l12 12"></path>
          </svg>
        </div>
        <h2>Scrawl</h2>
        <p>
          Get our free app. It won't take up space on your phone and also works
          offline!
        </p>
        <button class="install-btn" @click="install">Install</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      deferredPrompt: null,
    };
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
      //TODO: Make user dismiss 3 times and dont prompt
    },
    async install() {
      this.deferredPrompt.prompt();
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
};
</script>

<style lang="scss" scoped>
.home {
  .content {
    background: wheat;
  }

  text-align: center;

  .heading {
    margin-top: 40%;
    font-family: "Twinkle Star", cursive;
    font-size: 2.5em;
    transform: rotate(325deg);

    h1 {
      color: black;
    }

    .pen {
      animation-duration: 3s;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .cta {
    z-index: 0;
    position: absolute;
    bottom: 10%;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding-bottom: 20px;
    padding-top: 20px;

    .log-in {
      content: "";
      appearance: none;
      width: 90%;
      font-size: 1.2em;
      padding: 15px;
      background-color: #ff91ff;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      line-height: 1.5;
      margin: 0;
      outline: none;
      text-align: center;
      text-transform: none;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      color: black;
      border: solid 2px black;
      padding-left: 24px;
      padding-right: 24px;
      position: relative;

      &:after {
        content: "";
        background-color: black;
        border-radius: 4px;
        left: 0;
        top: 0;
        transform: translate(6px, 6px);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
      }

      &:hover {
        background-color: #d675d6;
        left: 1px;
        top: 1px;

        &:after {
          transform: translate(1\px, 1px);
        }
      }
    }

    .sign-up {
      font-size: 0.8em;
      display: block;
      padding-top: 5%;
      color: black;
      font-size: 1.3em;
    }
  }

  .pwa-banner {
    position: fixed;
    bottom: 0;
    padding: 10px;
    background: rgb(184, 109, 239);
    padding-bottom: 50px;
    border-top: solid 5px black;
    border-radius: 10px;
    h2 {
      font-family: "Twinkle Star", cursive;
    }
    .install-btn {
      height: 50px;
      width: 90%;
      content: "";
      appearance: none;
      width: 90%;
      font-size: 1.2em;
      padding: 15px;
      background-color: #f1427c;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      line-height: 0;
      margin: 0;
      outline: none;
      text-align: center;
      text-transform: none;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      color: black;
      border: solid 2px black;
      padding-left: 24px;
      padding-right: 24px;
      position: relative;

      &:after {
        content: "";
        background-color: black;
        border-radius: 4px;
        left: 0;
        top: 0;
        transform: translate(6px, 6px);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
      }
    }
    .close {
      display: inline-block;
      position: absolute;
      right: 3%;
    }
  }
}
</style>
