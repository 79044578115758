<template>
  <router-view v-slot="{ Component }">
    <keep-alive include="search">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
import Parse from "parse";

export default {
  name: "app",
  created() {
    Parse.initialize("scrawl");
    // eslint-disable-next-line
    Parse.serverURL = process.env.VUE_APP_API;

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("./sw.js")
        .then((serviceWorker) => {
          console.log("Service Worker registered: ", serviceWorker);
        })
        .catch((error) => {
          console.error("Error registering the Service Worker: ", error);
        });
    }
  },
};
</script>

<style lang="scss">
#app {
  left: 0;
  right: 0;
  height: 100vh;
  position: fixed;
}
</style>
